import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'

import { StockistsForm } from '../../../components'
import { media } from '../../../styles/utils'
import { container, padding, hoverState, type } from '../../../styles/global'

const BlockStockists = props => {
    return (
        <Wrapper>
            <Container>
                <StockistsForm heading={'Find a stockist via our stockists map'} />
            </Container>
        </Wrapper>
    )
}

const Container = styled.div``

const Wrapper = styled.div`
    ${tw`w-full bg-green`};
    padding-top: 8.5rem;
    padding-bottom: 9.375rem;

    ${Container} {
        ${container};
        ${padding};
    }

    .form-heading {
        ${tw`mx-auto font-body text-white text-center`};
        font-weight: 500;
        font-size: 2rem;
        line-height: 1;
    }

    button {
        margin-top: 4rem;
        margin-left: auto;
        margin-right: auto;
        padding: 1rem 4rem 1.2rem !important;
        ${tw`font-body text-white`};
        font-weight: 300;
        font-size: 1rem;
        line-height: 1;
        min-width: 18rem!important;

        &:hover {
            ${tw`bg-white text-green`};
        }
    }
`

BlockStockists.wrapper = css`
    margin-bottom: 0;
`

export default BlockStockists
