import React, { useState, useContext } from 'react'
import { useStaticQuery, graphql, Link, navigate } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import { motion, AnimatePresence } from 'framer-motion'
import MobileMenu from '../MobileMenu'
import Submenu from '../Submenu'
import { MobileMenuContext } from '../MobileMenu/context'
import { getGlobalData } from '../../utils'

import { media } from '../../styles/utils'
import { container, padding, hoverState, type } from '../../styles/global'
import { renderLink } from '../../utils'

import logo from '../../assets/images/logos/sunstar-flooring-logo.svg'
import newLogo from '../../assets/images/logos/new-logo.png'

export const headerHeight = '6.25rem'

const Header = props => {
    const { global: data } = getGlobalData()
    const mobileMenu = useContext(MobileMenuContext)

    console.log('header data', data?.header_nav)

    const renderMenuItems = menu => {
        if (!menu) return
        return menu.map((item, i) => {
            const type = item?.link_type
            if (type === 'link') {
                const link = item?.link
                if (!link) return <></>
                return renderLink(link, i)
            }
            if (type === 'submenu') {
                const label = item?.label
                return (
                    <Submenu
                        label={label}
                        menu={[...item?.submenu_links]}
                    />
                )
            }
        })
    }

    return (
        <>
            {/* Mobile Menu */}
            <AnimatePresence>
                {mobileMenu.active && (
                    <MobileMenuWrapper
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <MobileMenu menu={[...data?.header_nav]} />
                    </MobileMenuWrapper>
                )}
            </AnimatePresence>

            {/* Desktop Menu */}
            <Wrapper>
                <Container>
                    <Grid>
                        <Left>
                            <HomeLink
                                onClick={() => {
                                    navigate('/')
                                    if (mobileMenu.active) {
                                        mobileMenu.toggleActive()
                                    }
                                }}
                            >
                                {/* <Logo src={logo} /> */}
                                <Logo src={newLogo} />
                            </HomeLink>
                        </Left>

                        {/* Desktop Only */}
                        <Menu>
                            {renderMenuItems(data?.header_nav)}
                        </Menu>

                        {/* Tablet Only */}
                        <MobileNavToggle
                            onClick={() => {
                                mobileMenu.toggleActive()
                            }}
                        >
                            {!mobileMenu.active && (
                                <svg className="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                                </svg>
                            )}
                            {mobileMenu.active && (
                                <svg className="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            )}
                        </MobileNavToggle>
                    </Grid>
                </Container>
            </Wrapper>
        </>
    )
}

// Layout

const Wrapper = styled.header`
    ${tw`z-20 fixed top-0 inset-x-0 w-full bg-white`};
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
`

const Container = styled.div`
    ${container}
    ${padding}
`

const Left = styled.div``
const Right = styled.div``

const Grid = styled.div`
    height: ${headerHeight};
    ${tw`flex justify-between items-center w-full`};

    ${Left}, ${Right} {
        ${tw`flex`}
    }

    ${Left} {
        ${tw`mr-8`};
    }
`

// Logo

const Logo = styled.img``

const HomeLink = styled.div`
    height: 3.125rem;
    cursor: pointer;

    ${Logo} {
        ${tw`h-full`};
        margin-right: 0.6rem;
        width: auto;
    }
`

// Menu

const menuLink = css`
    ${tw`inline-block mx-2 text-black uppercase cursor-pointer`};
    ${type.headerLinks};
    ${hoverState};
`

const MenuItem = styled.a``
const Menu = styled.div`
    flex: 1;
    max-width: 62rem;
    ${tw`flex justify-between`};

    @media (max-width: 1440px) {
        display: none;
    }

    .link, a {
        ${menuLink};
    }

    > .link, > a {
        &:last-child {
            ${tw`mr-0`};
            font-weight: 700;
        }
    }

    .submenu-label {
        ${tw`text-black uppercase cursor-pointer`};
        ${type.headerLinks};
        ${hoverState};
    }
`

// Mobile Nav Toggle

const MobileNavToggle = styled.div`
    display: none;
    ${type.headerLinks};
    cursor: pointer;

    @media (max-width: 1440px) {
        ${tw`block`};
    }
`

// Mobile Menu

const MobileMenuWrapper = styled(motion.div)`
    ${tw`z-20 fixed inset-0 bg-white`};
    top: ${headerHeight};
`

export default Header
