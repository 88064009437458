import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import { navigate } from 'gatsby'

import { media } from '../../../styles/utils'
import { container, padding, hoverState, type, bgImage, button } from '../../../styles/global'
import imagineImage from '../../../assets/images/imagine-floors.jpg'

const BlockImagine = props => {
    return (
        <Wrapper>
            <Container>
                <Heading>
                    Explore Imagine Floors
                </Heading>
                <a
                    className="link"
                    href='https://imaginefloors.com.au/'
                    target={'_blank'}
                >
                    Visit the Imagine Floors website
                </a>
            </Container>
        </Wrapper>
    )
}

const Container = styled.div``
const Heading = styled.div``

const Wrapper = styled.div`
    ${tw`w-full`};
    padding-top: 9rem;
    padding-bottom: 9.375rem;
    background-image: url(${imagineImage});
    ${bgImage};

    ${Container} {
        ${container};
        ${padding};
        ${tw`flex flex-col items-center`};
    }

    ${Heading} {
        ${tw`mx-auto font-body text-white text-center`};
        font-weight: 500;
        font-size: 2rem;
        line-height: 1;
    }

    a {
        display: inline-block;
        margin-top: 4rem;
        margin-left: auto;
        margin-right: auto;
        padding: 1rem 4rem 1.2rem !important;
        ${tw`font-body text-white`};
        font-weight: 300;
        font-size: 1rem;
        line-height: 1;
        min-width: 18rem!important;

        &:hover {
            ${tw`bg-white text-green`};
        }

        appearance: none;
        box-shadow: none;
        background-color: transparent;
        ${button}

        &:focus {
            outline: none;
        }
    }
`

BlockImagine.wrapper = css`
    margin-bottom: 0;
`

export default BlockImagine
